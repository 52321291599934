// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import * as RailsUJS from "@rails/ujs"
import * as RailsActiveStorage from "@rails/activestorage"
import "../shared"

RailsUJS.start()
RailsActiveStorage.start()
